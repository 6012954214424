@charset "UTF-8";
/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/*.site-main .external-link {
    @include icon-after('\e80d');
}*/
h4, h5, h6 {
  color: #555555; }

.hidden {
  display: none !important; }

@media screen and (min-width: 992px) {
  .block-menu-block {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    -ms-column-gap: 15px;
    -o-column-gap: 15px;
    column-gap: 15px;
    margin-bottom: 1em; } }

.aboutimg {
  border: 6px solid #fff;
  float: none;
  margin: 20px auto 20px auto;
  display: block;
  max-width: 170px;
  box-shadow: 0px 0px 1px #666; }
  @media screen and (min-width: 992px) {
    .aboutimg {
      float: left;
      margin: 20px 25px 15px 0; } }

#main-content p.title {
  font-weight: 700;
  margin-top: 20px;
  font-size: 16px; }

/*#node-13
{
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1180px;
}*/
/***********************************************
        NAV
***********************************************/
#main-nav ul li.level-1 {
  padding: 0 20px; }

#main-nav ul li.home {
  padding: 0; }

#primary-nav .level-1 {
  /* width: 16.5%;*/
  width: 25%; }
  @media screen and (min-width: 768px) {
    #primary-nav .level-1 {
      padding: 8px 11px; } }

/***********************************************
        SLIDERS RESPONSIVE
***********************************************/
#slider-container #slider {
  position: relative;
  margin: 0 auto;
  -webkit-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear; }

@media screen and (max-width: 767px) {
  #slider-container {
    display: none; } }

#slider-container div.menu0 {
  background: url("../images/sliders/slider01.jpg") no-repeat center top; }

#slider-container div.menu1 {
  background: url("../images/sliders/slider02.jpg") no-repeat center top; }

#slider-container div.menu2 {
  background: url("../images/sliders/slider03.jpg") no-repeat center top; }

#slide-controls .control-1 {
  background: #777777; }
  #slide-controls .control-1:hover {
    background: rgba(119, 119, 119, 0.7); }

#slide-controls .control-2 {
  background: #555555; }
  #slide-controls .control-2:hover {
    background: rgba(85, 85, 85, 0.7); }

#slide-controls .control-3 {
  background: black; }
  #slide-controls .control-3:hover {
    background: rgba(0, 0, 0, 0.7); }

#slide-controls .control h3 {
  font-size: 30px;
  color: white;
  margin: 29px 0 20px 0;
  font-family: "1898Sans-Regular", sans-serif; }

#slide-controls .control p {
  font-size: 15px;
  color: white;
  margin: 15px; }
  #slide-controls .control p.desc {
    min-height: 40px; }
  #slide-controls .control p a {
    display: inline-block;
    padding: 10px 20px;
    background: #cf4520;
    border: 2px solid #fff;
    color: #fff;
    font-size: 12px;
    border-radius: 20px; }
    #slide-controls .control p a:after {
      content: '';
      display: none; }
    #slide-controls .control p a:hover {
      background: #e7751d;
      text-decoration: none; }

#slide-controls .control {
  height: 83px;
  margin-top: 129px; }
  #slide-controls .control:hover {
    height: 212px;
    margin-top: 0; }

@media (min-width: 1280px) {
  #slide-controls {
    width: 1140px;
    position: absolute;
    left: 50%;
    margin-left: -570px;
    bottom: 0; }
    #slide-controls .control {
      text-align: center;
      width: 380px;
      overflow: hidden;
      display: block;
      float: left; }
  #slider-container #slider {
    height: 585px; }
  #sidebar {
    width: 280px; }
  footer .department {
    width: 270px; } }

@media (min-width: 1280px) and (min-height: 701px) {
  #slide-controls {
    height: 212px; } }

@media (max-width: 1279px) and (min-width: 980px) {
  #slide-controls {
    width: 930px;
    position: absolute;
    left: 50%;
    margin-left: -470px;
    bottom: 0; }
  #slide-controls .control {
    text-align: center;
    width: 310px;
    overflow: hidden;
    display: block;
    float: left; }
  #slider-container #slider {
    height: 400px;
    background-size: cover; }
  #sidebar {
    width: 240px; } }

@media (max-width: 979px) and (min-width: 768px) {
  #slide-controls {
    width: 720px;
    position: absolute;
    left: 50%;
    margin-left: -360px;
    bottom: 0; }
  #slide-controls .control {
    height: 63px;
    margin-top: 114px;
    text-align: center;
    width: 240px;
    overflow: hidden;
    display: block;
    float: left; }
  #slide-controls .control h3 {
    font-size: 30px;
    margin: 19px 0 10px 0; }
  #slide-controls .control p {
    font-size: 14px;
    color: white;
    margin: 15px; }
  #slide-controls .control:hover {
    height: 177px; }
  #slider-container #slider {
    height: 300px;
    background-size: cover; } }

/* Heights */
@media (min-height: 801px) {
  #slider-container #slider {
    height: 585px; } }

@media (max-height: 800px) and (min-height: 701px) {
  #slider-container #slider {
    height: 450px;
    background-size: cover; } }

@media (max-height: 700px) {
  #slider-container #slider {
    height: 320px;
    background-size: cover; }
  #slide-controls .control {
    height: 63px;
    margin-top: 114px;
    text-align: center;
    width: 33.33333333%;
    overflow: hidden;
    display: block;
    float: left; }
    #slide-controls .control:hover {
      height: 177px; }
  #slide-controls .control h3 {
    font-size: 30px;
    margin: 19px 0 10px 0; }
  #slide-controls .control p {
    font-size: 14px;
    color: white;
    margin: 15px; }
  /*#slide-controls .control:hover {
        height:172px;
    }*/ }

/************** HOME STYLES *************/
.front {
  /* left col */
  /* pre footer */ }
  .front h1 {
    font-size: 45px;
    text-align: center;
    margin: 65px 0;
    font-family: "1898Sans-Regular", sans-serif; }
  .front h3.pane-title {
    font-size: 24px;
    margin-top: 0;
    padding: 9px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd; }
  .front a.read_more {
    display: inline-block; }
    .front a.read_more:after {
      color: #cf4520;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      font-size: 70%;
      display: inline-block;
      text-decoration: inherit;
      margin-right: .2em;
      padding-left: 5px;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: ""; }
    .front a.read_more:hover:after, .front a.read_more:active:after {
      text-decoration: none; }
  .front .taylor .radix-layouts-half h3, .front .taylor .radix-layouts-quarter1 h3 {
    margin-bottom: 35px; }
  .front .taylor .radix-layouts-half h6 {
    color: #b31b1b;
    font-size: 15px;
    margin: 0px; }
  .front .taylor .radix-layouts-half h4 {
    color: #555555;
    font-size: 15px;
    margin-bottom: 0; }
  .front .taylor .radix-layouts-half p {
    margin-top: 0; }
  .front .taylor .radix-layouts-half p img.media-element {
    float: left;
    margin: 0 12px 12px 0; }
  .front #prefooter {
    text-align: center;
    background: url("../images/prefooter-img.jpg");
    height: 295px;
    /* prefooter fixes */ }
    .front #prefooter .block {
      display: inline-block;
      width: 382px;
      height: 295px;
      padding-top: 40px; }
      .front #prefooter .block h2 {
        text-transform: uppercase;
        font-size: 23px;
        margin: 20px 0; }
      .front #prefooter .block p {
        color: #000;
        font-size: 13px; }
      .front #prefooter .block .icon {
        width: 69px;
        height: 79px;
        background: url("../images/prefooter-icons.png") no-repeat;
        margin: 0 auto; }
      .front #prefooter .block .contact-icon {
        background-position: 0 0; }
      .front #prefooter .block .schedule-icon {
        background-position: -69px 0; }
      .front #prefooter .block .donate-icon {
        background-position: -138px 0; }
    .front #prefooter #block-block-9:hover,
    .front #prefooter #block-block-11:hover,
    .front #prefooter #block-block-12:hover {
      background: url("../images/prefooter-hover-bg.png"); }
    .front #prefooter #block-block-9:hover .icon {
      background-position: 0 -79px; }
    .front #prefooter #block-block-11:hover .icon {
      background-position: -69px -79px; }
    .front #prefooter #block-block-12:hover .icon {
      background-position: -138px -79px; }
    .front #prefooter a:hover, .front #prefooter a:hover * {
      text-decoration: none; }

/* right colum */
.front .radix-layouts-quarter2 {
  font-size: 13px;
  line-height: 22px;
  /*.physicians .icon{ background-position: 0 0;}
        .physiatrist .icon{ background-position: -32px 0;}
        .directions .icon{ background-position: -64px 0;}*/ }
  .front .radix-layouts-quarter2 .bloque {
    margin-bottom: 30px; }
  .front .radix-layouts-quarter2 form > fieldset {
    margin: 0;
    padding: 0;
    border: 0; }
  .front .radix-layouts-quarter2 form input {
    width: 135px;
    margin-bottom: 10px;
    color: #524e47;
    padding: 10px 8px;
    font-size: 12px;
    border: 1px solid #929292; }
  .front .radix-layouts-quarter2 form input#Login {
    margin-right: 3px; }
  .front .radix-layouts-quarter2 form input#email {
    width: 180px; }
  .front .radix-layouts-quarter2 form .forgot {
    font-size: 12px;
    display: inline-block; }
  .front .radix-layouts-quarter2 form .signin {
    cursor: pointer;
    width: 79px;
    height: 40px;
    background: #970000;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    float: right; }
    .front .radix-layouts-quarter2 form .signin:hover {
      background: #524e47; }
  .front .radix-layouts-quarter2 .menu {
    border-top: 1px solid #C0C0C0; }
  .front .radix-layouts-quarter2 .menu .item {
    border-bottom: 1px solid #C0C0C0; }
  .front .radix-layouts-quarter2 .menu a {
    font-size: 20px;
    display: block;
    margin: 10px 0; }
  .front .radix-layouts-quarter2 .menu a:hover {
    text-decoration: none; }
  .front .radix-layouts-quarter2 .menu span {
    background: url("../images/right-menu-icon.png") no-repeat;
    width: 32px;
    height: 32px;
    display: inline-block;
    vertical-align: middle; }
  .front .radix-layouts-quarter2 .physicians span {
    background-position: 0 0; }
  .front .radix-layouts-quarter2 .physiatrist span {
    background-position: -32px 0; }
  .front .radix-layouts-quarter2 .directions span {
    background-position: -64px 0; }

.page-blog ul.links .blog_usernames_blog {
  display: none; }

.blog-summary {
  margin-bottom: 20px; }

.blog-link {
  margin-bottom: 30px !important; }

#tabs {
  margin-bottom: 0 !important; }

#main .main-content p:first-child {
  margin-top: 30px; }

div#panels-ipe-paneid-9 div div div.pane-content,
div.pane-menu-menu-home-related-links div.pane-content {
  margin-top: -20px; }

div.pane-menu-menu-home-related-links {
  margin-bottom: 30px;
  margin-top: -35px; }
  div.pane-menu-menu-home-related-links h3 {
    border: none !important;
    font-size: 20px !important;
    padding: 14px 0 12px 0px !important; }
  div.pane-menu-menu-home-related-links ul {
    border-top: none !important;
    padding-left: 0; }
    div.pane-menu-menu-home-related-links ul li {
      font-size: 16px;
      list-style-image: none !important;
      list-style: disc !important;
      margin-left: 1.5em; }
      div.pane-menu-menu-home-related-links ul li a {
        font-size: 16px !important; }

/* login sidebar */
.taylor-column-content-region-inner .login form,
#sidebar .login form {
  display: none; }

/*lightbox */
.ligthbox-content {
  float: left;
  margin-top: 5px !important;
  margin-right: 10px; }

.lightbox-presentation:hover img {
  opacity: 0.8; }

.lightbox-presentation span {
  font-size: 13px;
  display: block;
  text-align: center;
  margin-top: 5px; }

.main-content p a.lightbox-processed[href^="http"]:after {
  content: none; }

img.file-icon {
  display: none; }

/* Sidebar */
.information-sidebar {
  /* linkable boxes */ }
  .information-sidebar .block {
    border: none;
    padding: 0 0 20px 0; }
  .information-sidebar .bloque {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 15px;
    margin-bottom: 20px; }
  .information-sidebar .bloque:last-of-type {
    border-color: transparent; }
  .information-sidebar > div > .block:last-of-type > .content > .bloque {
    border-color: transparent; }
  .information-sidebar form > fieldset {
    margin: 0;
    padding: 0;
    border: 0; }
  .information-sidebar form input, .information-sidebar form textarea, .information-sidebar form select {
    margin: 0;
    width: 150px;
    padding: 8px;
    border: 1px solid #666666; }
  .information-sidebar form input {
    width: 115px;
    margin-bottom: 10px;
    color: #524e47;
    padding: 6px 8px;
    font-size: 12px; }
  .information-sidebar form .forgot {
    font-size: 12px;
    display: inline-block; }
  .information-sidebar form .signin {
    cursor: pointer;
    width: 79px;
    height: 40px;
    background: #524e47;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    float: right; }
  .information-sidebar form .signin:hover {
    background: #970000; }
  .information-sidebar .bloque.one.banner {
    overflow-x: hidden; }
  .information-sidebar .bloque.one.for_patients {
    border-top: 1px solid #c0c0c0;
    border-bottom: 0; }
  .information-sidebar .bloque.one.for_patients h2 {
    padding: 18px 0 !important;
    font-size: 20px;
    font-weight: 600;
    color: #970000;
    text-transform: uppercase;
    text-align: left;
    border-bottom: 1px solid #c0c0c0; }
  .information-sidebar .bloque.one.for_patients hr {
    height: 1px;
    width: 100%;
    margin: 0 0 10px 0;
    background-color: #c0c0c0;
    border: 0; }
  .information-sidebar .bloque.one.for_patients a {
    background: url(../images/sidebar_for-patients_a_arr.png) center left no-repeat;
    font-size: 15px;
    padding-left: 15px;
    color: #333; }
  .information-sidebar .bloque.two.login {
    overflow-x: hidden;
    font-size: 13px; }
  .information-sidebar .bloque.three, .information-sidebar .bloque.four {
    font-size: 13px;
    text-align: center;
    color: #000;
    display: block; }
  .information-sidebar .bloque.schedule span, .information-sidebar .bloque.donate span, .information-sidebar .bloque.contact span, .information-sidebar .bloque.schedule span {
    text-align: center; }
    .information-sidebar .bloque.schedule span:after, .information-sidebar .bloque.donate span:after, .information-sidebar .bloque.contact span:after, .information-sidebar .bloque.schedule span:after {
      color: #e7751d;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      font-size: 70%;
      display: inline-block;
      text-decoration: inherit;
      margin-right: .2em;
      padding-left: 5px;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: ""; }
    .information-sidebar .bloque.schedule span:hover:after, .information-sidebar .bloque.schedule span:active:after, .information-sidebar .bloque.donate span:hover:after, .information-sidebar .bloque.donate span:active:after, .information-sidebar .bloque.contact span:hover:after, .information-sidebar .bloque.contact span:active:after, .information-sidebar .bloque.schedule span:hover:after, .information-sidebar .bloque.schedule span:active:after {
      text-decoration: none; }
  .information-sidebar .bloque.three .icon {
    width: 69px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 5px; }
  .information-sidebar .bloque h2 {
    font-size: 23px;
    text-align: center;
    margin: 0; }
  .information-sidebar #block-block-5 {
    border-bottom: none;
    margin: 0; }
  .information-sidebar .bloque.two {
    border-bottom: 1px solid #dddddd; }
  .information-sidebar .bloque.two:hover {
    background: transparent; }
  .information-sidebar a.bloque {
    padding-top: 15px; }
  .information-sidebar a.bloque:hover, .information-sidebar a.bloque:hover * {
    text-decoration: none; }
  .information-sidebar a.bloque:hover {
    background: #f0f0f0; }
  .information-sidebar .bloque:last-of-type:hover {
    border-color: #dddddd; }
  .information-sidebar > .block:first-of-type {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 20px;
    padding-bottom: 0; }
  .information-sidebar > .block:first-of-type > div.bloque.two {
    padding-bottom: 0 !important;
    margin-bottom: 0; }

p.p_signin {
  text-align: center; }

a.btn_signin {
  padding: 7px 15px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  max-width: 152px;
  max-height: 27px;
  background: #970000; }
  a.btn_signin:hover {
    background: #524e47; }

/* Physicians */
.our_physicians {
  margin-bottom: 40px; }
  .our_physicians .physician > h3 {
    border-top: 0;
    clear: both;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 15px;
    padding-top: 10px; }
  .our_physicians .physician > div {
    display: inline-block;
    float: left;
    position: relative; }
  .our_physicians .physician > div:nth-of-type(1) {
    /*@include breakpoint( (max-width: 480px) ) {
                float:none;
                width:144px;
                margin-left:auto;
                margin-right:auto;
                display:block;
            }*/ }
    @media screen and (max-width: 767px) {
      .our_physicians .physician > div:nth-of-type(1) {
        margin-bottom: 20px;
        margin-right: 4%; } }
  .our_physicians .physician > div:nth-of-type(2) {
    width: 31%;
    margin: 0 3%; }
    @media screen and (max-width: 767px) {
      .our_physicians .physician > div:nth-of-type(2) {
        margin-bottom: 20px; } }
  .our_physicians .physician > div:nth-of-type(3) {
    max-width: 40%; }
    @media screen and (max-width: 767px) {
      .our_physicians .physician > div:nth-of-type(3) {
        display: block;
        clear: both;
        float: none;
        width: 100%;
        max-width: 100%;
        margin: 0; } }
  .our_physicians .physician .image {
    border: 1px solid #dddddd;
    float: left;
    max-width: 144px;
    width: 144px;
    height: auto;
    max-height: 187px;
    overflow: hidden;
    position: relative; }
    .our_physicians .physician .image > .container {
      border: 5px solid #fff;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      margin: 0; }
  .our_physicians .physician .left_info {
    padding-bottom: 50px; }
    .our_physicians .physician .left_info > h5 {
      font-size: 17px;
      margin-top: 0; }
    .our_physicians .physician .left_info > ul > li {
      font-size: 13px;
      list-style-image: url(../images/physicians_specialty_check.png); }
  .our_physicians .physician .right_info {
    padding-bottom: 50px; }
    .our_physicians .physician .right_info > a {
      white-space: pre;
      float: left;
      clear: both; }
      @media screen and (max-width: 767px) {
        .our_physicians .physician .right_info > a {
          float: none;
          margin: 0 auto;
          display: block;
          position: initial;
          margin-top: 20px;
          text-align: center; } }

.our_physicians .physician .left_info > a, .physician_profile div.left a {
  font-size: 12px;
  color: #b31b1b;
  text-decoration: none; }

.our_physicians .physician .left_info > a > span.icon,
.physician_profile div.left a > span.icon {
  width: 32px;
  height: 32px;
  background: url(../images/btn_video.png) no-repeat center;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle; }

.our_physicians .physician > div > a {
  position: absolute;
  bottom: 0;
  left: 0; }

@media only screen and (max-width: 480px) {
  .our_physicians .physician > div:nth-of-type(1) {
    float: none;
    width: 144px;
    margin-left: auto;
    margin-right: auto;
    display: block; }
  .our_physicians .physician > div {
    width: 100% !important;
    display: block;
    clear: both;
    float: none;
    margin: 0; }
  .physician_profile > div {
    float: none !important;
    width: auto;
    clear: both; }
  .physician_profile > div:nth-of-type(2) {
    margin: 0 !important; } }

@media only screen and (min-width: 980px) {
  .our_physicians .physician .left_info,
  .our_physicians .physician .right_info {
    min-height: 150px; } }

/* Physician Profile */
.physician_profile {
  margin: 0px 0 30px 0; }

.physician_profile > div:nth-of-type(1) {
  float: left;
  width: 160px; }

.physician_profile > div:nth-of-type(2) {
  margin-left: 180px; }

.physician_profile > div:nth-of-type(2) > p {
  margin: 15px 0;
  line-height: 21px; }

.physician_profile > div:nth-of-type(2) > h3 {
  margin-top: 20px; }

.physician_profile > div:nth-of-type(2) > p > span {
  display: block;
  clear: both; }

.physician_profile > div:nth-of-type(2) > p > span.contact_title {
  margin-bottom: 5px; }

/* .physician_profile > div:nth-of-type(2) > p > span:not(.contact_title) { font-size:12px; } */
.physician_profile .field-name-field-profile-video .field-label {
  display: none; }

/* Locations */
#node-13 {
  margin-bottom: 40px; }
  #node-13 h2 {
    display: block;
    clear: both; }
  #node-13 iframe:first-of-type {
    margin: 20px 0 30px 0;
    border: 1px solid #999; }
  #node-13 .two-cols {
    display: block;
    clear: both;
    margin: 10px 0; }
    #node-13 .two-cols div {
      float: left;
      width: 48%;
      display: inline-block;
      font-size: 15px;
      margin-bottom: 30px; }
      @media screen and (max-width: 767px) {
        #node-13 .two-cols div {
          display: block;
          float: none;
          width: auto;
          clear: both;
          margin-bottom: 20px; } }
      #node-13 .two-cols div:first-of-type {
        margin-right: 4%; }
        @media screen and (max-width: 767px) {
          #node-13 .two-cols div:first-of-type {
            margin-bottom: 55px; } }
      #node-13 .two-cols div h3 {
        font-size: 18px;
        text-transform: none;
        color: #524e47;
        height: 50px; }
        @media screen and (max-width: 767px) {
          #node-13 .two-cols div h3 {
            height: auto; } }
      #node-13 .two-cols div p {
        height: 65px; }
      #node-13 .two-cols div a.btn_directions {
        padding: 7px 15px;
        font-size: 12px;
        font-weight: 600;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        max-width: 152px;
        max-height: 27px;
        background: #970000; }
        #node-13 .two-cols div a.btn_directions:hover {
          background: #524e47; }

/**** VIDEO RESPONSIVE ***/
.media-youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden; }

.media-youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

@media (min-width: 979px) and (max-width: 1024px) {
  /**/
  .front .radix-layouts-quarter2 form input,
  .front .radix-layouts-quarter2 form input#email {
    width: 90%; }
  .front .radix-layouts-quarter2 form .signin {
    float: left; }
  .front .radix-layouts-quarter2 .menu a {
    font-size: 16px; }
  /**/
  .front #prefooter .block {
    width: 284px; }
  .front #prefooter .block h2 {
    font-size: 16px; } }

@media (min-width: 1024px) and (max-width: 1279px) {
  .front .radix-layouts-quarter2 form input,
  .front .radix-layouts-quarter2 form input#email {
    width: 90%; }
  .front .radix-layouts-quarter2 form .signin {
    float: left; }
  .front .radix-layouts-quarter2 .menu a {
    font-size: 16px; }
  /**/
  .front #prefooter .block {
    width: 284px; }
  .front #prefooter .block h2 {
    font-size: 16px; } }

@media (max-width: 979px) {
  .information-sidebar .bloque .head img {
    display: block;
    margin: 8px auto; }
  .information-sidebar .block,
  .information-sidebar .bloque,
  .information-sidebar .bloque.one.for_patients h2 {
    text-align: center; }
  .taylor-column-content-region {
    float: none;
    display: block;
    width: 99%; }
  .front #prefooter .block {
    width: 30%; }
  .front #prefooter .block h2 {
    font-size: 17px; }
  .front #prefooter .block p:first-child {
    display: none; }
  .front #prefooter .block p {
    font-size: 12px; }
  /* */
  .front .radix-layouts-quarter2 form input {
    width: 300px;
    display: block; }
  .front .radix-layouts-quarter2 form .signin {
    float: none; }
  div.container-inline > div.form-item.form-type-textfield.form-item-search-keys {
    float: left;
    margin-left: 1%;
    width: 71%;
    margin-right: 3%; }
  div.container-inline > input.form-submit {
    float: left;
    width: 24%;
    clear: right;
    margin-top: 16px;
    font-size: 16px;
    margin-right: 1%;
    text-align: center;
    padding: .4em 5px; }
  .google-appliance-results-control-bar .google-appliance-search-stats {
    clear: both; } }

@media (max-width: 928px) {
  .front #prefooter .block p.block_details {
    display: none; } }

@media (max-width: 870px) {
  .front #prefooter .block h2 {
    min-height: 65px; } }

@media (max-width: 646px) {
  .front #prefooter {
    background: #dddddd;
    height: auto;
    padding-bottom: 10px; }
    .front #prefooter .block {
      width: 100%;
      display: block;
      padding-top: 10px;
      height: auto; }
      .front #prefooter .block h2 {
        margin: 0;
        min-height: 0; }
      .front #prefooter .block p.block_details {
        display: block; }
      .front #prefooter .block .icon {
        display: none; } }
